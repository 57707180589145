import React from 'react';
import LoginRedirect from '@atlassian/jira-common-navigation/src/login-redirect/index.tsx';
import {
	DASHBOARD_VIEW_SOURCENAME,
	VIEW_DASHBOARD_READONLY_EXPERIENCE,
} from '@atlassian/jira-dashboard-common/src/constants.tsx';
import { DashboardControllerContextConsumer } from '@atlassian/jira-dashboard-internal-common/src/controllers/dashboard/context.tsx';
import { DashboardController } from '@atlassian/jira-dashboard-internal-common/src/controllers/dashboard/main.tsx';
import { DashboardPageContainer } from '@atlassian/jira-dashboard-internal-common/src/ui/container/main.tsx';
import { EngagementBanners } from '@atlassian/jira-dashboard-internal-common/src/ui/dashboard-content/engagement-banners/index.tsx';
import { ColumnLayout } from '@atlassian/jira-dashboard-internal-common/src/ui/dashboard-content/layout/main.tsx';
import { DashboardContentContainer } from '@atlassian/jira-dashboard-internal-common/src/ui/dashboard-content/main.tsx';
import { useConnectGadgetTitles } from '@atlassian/jira-dashboard-internal-common/src/utils/use-gadget-title/index.tsx';
import { DashboardPermissionErrorPageAsync } from '@atlassian/jira-error-pages/src/async.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useDashboardResource } from '@atlassian/jira-router-resources-dashboard/src/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import messages from './messages.tsx';
import { EmptyDashboardPlaceholder } from './ui/empty-dashboard-placeholder/index.tsx';
import { GadgetReadOnly } from './ui/gadget-readonly/index.tsx';
import { HeaderReadOnly } from './ui/header-readonly/index.tsx';
import { UnconfiguredGadgetFallback } from './ui/unconfigured-gadget-fallback/index.tsx';

export const DashboardReadOnly = () => {
	const { data, error, loading } = useDashboardResource();

	const { registerConnectGadget, unregisterConnectGadget, setCustomTitle } =
		useConnectGadgetTitles();

	return (
		<DashboardContentContainer
			analyticsExperience={VIEW_DASHBOARD_READONLY_EXPERIENCE}
			resourceData={{ data, error, loading }}
			permissionErrorFallback={<DashboardPermissionErrorPageAsync />}
			saveCustomGadgetTitle={setCustomTitle}
		>
			{data ? (
				<DashboardController id={data.id} automaticRefreshMs={data.automaticRefreshMs}>
					<HeaderReadOnly
						data={data}
						isEditLinkEnabled={data.writable}
						banners={
							<>
								<EngagementBanners gadgets={data.gadgets} dashboardId={data.id} />
							</>
						}
					/>
					<ColumnLayout
						data={data}
						dnd={null}
						columnPlaceholder={null}
						layoutPlaceholder={<EmptyDashboardPlaceholder hasEditPermissions={data.writable} />}
						onLayoutReposition={null}
					>
						{({
							dragHandleProps: _dragHandleProps,
							isDraggable: _isDraggable,
							isDragging: _isDragging,
							isAnyDragging: _isAnyDragging,
							...gadgetProps
						}) => (
							<DashboardControllerContextConsumer>
								{({ refreshId }) => (
									<GadgetReadOnly
										dashboardId={data.id}
										refreshId={refreshId}
										unconfiguredFallback={
											<UnconfiguredGadgetFallback
												id={gadgetProps.data.id}
												hasEditPermissions={data.writable}
											/>
										}
										{...gadgetProps}
										onRegisterConnectGadget={registerConnectGadget}
										onUnregisterConnectGadget={unregisterConnectGadget}
									/>
								)}
							</DashboardControllerContextConsumer>
						)}
					</ColumnLayout>
				</DashboardController>
			) : null}
		</DashboardContentContainer>
	);
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => {
	const { data, error, loading } = useDashboardResource();
	const { formatMessage } = useIntl();
	return (
		<UFOSegment name="dashboard-view">
			<DashboardPageContainer
				analyticsSourceName={DASHBOARD_VIEW_SOURCENAME}
				analyticsExperience={VIEW_DASHBOARD_READONLY_EXPERIENCE}
				documentTitle={data?.title ?? formatMessage(messages.pageTitle)}
				unauthenticatedFallback={<LoginRedirect />}
				resourceData={{ data, error, loading }}
			>
				<DashboardReadOnly />
			</DashboardPageContainer>
		</UFOSegment>
	);
};
