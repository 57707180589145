import { useState, useEffect } from 'react';
import type { GadgetData } from '../types.tsx';
import { checkGadgetType } from './check-gadget-type.tsx';

export const useDashboardHasGadgetType = (
	gadgets: GadgetData[],
	webResourceMap: {
		[key: string]: string;
	},
) => {
	const [dashboardHasGadgetType, setDashboardHasGadgetType] = useState(false);
	useEffect(() => {
		setDashboardHasGadgetType(checkDashboardHasGadgetType(webResourceMap, gadgets));
	}, [gadgets, webResourceMap]);
	return dashboardHasGadgetType;
};

const checkDashboardHasGadgetType = (
	webResourceMap: {
		[key: string]: string;
	},
	gadgets: GadgetData[],
): boolean => {
	return gadgets.some((gadget) => checkGadgetType(webResourceMap, gadget.amdModule));
};
