import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	activityStreamsSurveyBannerTitle: {
		defaultMessage: 'Help us improve the Activity Stream gadget!',
		id: 'dashboard-internal-common.common.gadget.activity-streams-survey-banner.activity-streams-survey-banner-title.non-final',
		description: 'Title of the activity streams survey banner.',
	},
	activityStreamsSurveyBannerMessage: {
		defaultMessage:
			"As part of our efforts to improve the dashboard experience, we're focusing on the Activity Stream gadget. If you'd like to leave feedback, please fill out <Link>this survey</Link>.",
		id: 'dashboard-internal-common.common.gadget.activity-streams-survey-banner.activity-streams-survey-banner-message.non-final',
		description:
			'An information section message to be displayed to the user about an activity streams survey.',
	},
	activityStreamsSurveyBannerDismiss: {
		defaultMessage: 'Dismiss',
		id: 'dashboard-internal-common.common.gadget.activity-streams-survey-banner.activity-streams-survey-banner-dismiss.non-final',
		description: 'Label for the button to dimiss the activity streams survey banner.',
	},
});
