import React, { type ReactNode } from 'react';
import Link from '@atlaskit/link';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { useIntl } from '@atlassian/jira-intl';
import { ACTIVITY_STREAMS_SURVEY_BANNER_DISMISS } from '../../constants.tsx';
import messages from './messages.tsx';

export const ActivityStreamsSurveyBanner = ({ onDismiss }: { onDismiss: () => void }) => {
	const { formatMessage } = useIntl();
	return (
		<SectionMessage
			appearance="discovery"
			actions={[
				<SectionMessageAction key={ACTIVITY_STREAMS_SURVEY_BANNER_DISMISS} onClick={onDismiss}>
					{formatMessage(messages.activityStreamsSurveyBannerDismiss)}
				</SectionMessageAction>,
			]}
			title={formatMessage(messages.activityStreamsSurveyBannerTitle)}
		>
			{formatMessage(messages.activityStreamsSurveyBannerMessage, {
				Link: (chunks: ReactNode[]) => (
					<Link href="https://surveys.atlassian.com/jfe/form/SV_bdRPSVFIEECKZYa">{chunks}</Link>
				),
			})}
		</SectionMessage>
	);
};
