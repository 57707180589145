import { useEffect } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { type FlagConfiguration, toFlagId, useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useRouterActions } from '@atlassian/react-resource-router';
import { clickedWithShortcutKeys } from '../utils/events/index.tsx';
import messages from './messages.tsx';

export const MAX_URL_LENGTH = 3984;

// Absolutely exclude any paths that contain the following regex
const EXCLUDED_PATHS_OLD = [/^\/rest\/(?:gadget|api)\/1\.0\//, /^\/plugins\/servlet\/gadgets\/ifr/];

// Issue Table meatball menu links
// These have been explicitly ignored as they will generally open modals or perform actions
const EXCLUDED_ISSUE_TABLE_PATHS = [
	// Assign. The anchor tag it belongs to opens a modal in the monolith.
	/^\/secure\/AssignIssue!default\.jspa/,
	// Assign to me. The anchor tag it belongs to reassigns then reloads page.
	/^\/secure\/AssignIssue\.jspa/,
	// Vote and watch. The anchor tag it belongs to votes/watches on the issue then reloads page.
	/^\/secure\/VoteOrWatchIssue\.jspa/,
	// Rank top. The anchor tag it belongs to ranks the issue to the top then reloads page.
	/^\/secure\/RankTop\.jspa/,
	// Rank bottom. The anchor tag it belongs to ranks the issue to the bottom then reloads page.
	/^\/secure\/RankBottom\.jspa/,
	// Create sub task. The anchor tag it belongs opens a modal in SPA (not monolith)
	/^\/secure\/CreateSubTaskIssue!default\.jspa/,
	// Permission helper. The anchor tag it belongs to opens a modal in monolith.
	/^\/notimportant/,
	// Workflow page. This is when a user transitions an issue, it can open a modal or refresh the modal.
	/^\/secure\/WorkflowUIDispatcher\.jspa/,
];

const EXCLUDED_PATHS_NEW = [
	/^\/rest\/(?:gadget|api)\/1\.0\//,
	/^\/plugins\/servlet\/gadgets\/ifr/,
	...EXCLUDED_ISSUE_TABLE_PATHS,
];

const isExcludedPath = (path: string) => {
	const EXCLUDED_PATHS_WITH_FG = fg('endeavour_announce_options_on_issue_table_gadgets')
		? EXCLUDED_PATHS_NEW
		: EXCLUDED_PATHS_OLD;

	return EXCLUDED_PATHS_WITH_FG.some((regex) => regex.test(path));
};

// Anchor tags inside gadgets uses href to navigate to another page. This hook is to listen to
// the clicks on anchor tags and navigate using RRR's push method for spa transition.
export const useLinkInterceptor = (
	element: HTMLElement | Document | null | undefined,
	isLoading: boolean,
) => {
	const { push } = useRouterActions();
	const { showFlag } = useFlagsService();

	useEffect(() => {
		if (isLoading) {
			// Replace with lodash/noop
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			return () => {};
		}

		const linkClickHandler = (event: Event) => {
			const anchor =
				event.target instanceof HTMLAnchorElement
					? event.target
					: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						(event.target as Element).closest?.('a');

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			if (anchor == null || window.location.hostname !== anchor.hostname) {
				// skip if it is not an anchor or to external link
				return;
			}
			if (anchor.href && anchor.href.length > MAX_URL_LENGTH) {
				event.preventDefault();
				const flagConfig: FlagConfiguration = {
					key: toFlagId('urlTooLongWarning'),
					type: 'error',
					title: messages.linkInterceptorUrlTooLongTitle,
					description: messages.linkInterceptorUrlTooLongDescription,
				};
				showFlag(flagConfig);
			} else if (
				anchor.pathname &&
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				!clickedWithShortcutKeys(event as MouseEvent) &&
				// some links from the monolith code are invalid and calls
				// event.preventDefault (e.g. popups), so we filter those out
				!event.defaultPrevented &&
				!isExcludedPath(anchor.pathname)
			) {
				event.preventDefault();
				push(anchor.href);
			}
		};
		element?.addEventListener('click', linkClickHandler);

		return () => {
			element?.removeEventListener('click', linkClickHandler);
		};
	}, [element, isLoading, push, showFlag]);
};
