import { useState, useEffect } from 'react';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';

export const useDashboardBannerState = (
	dashboardBannerStorageKey: string,
	dashboardId: string,
	bannerPerDashboard: boolean,
) => {
	const [isDashboardBannerDismissed, setIsDashboardBannerDismissed] = useState(false);
	// Would change to 'jira-dashboard-banner-state' but then local storage
	// for previous deprecation dismissal would be ignored. Should be temporary anyways
	const store = createLocalStorageProvider('jira-dashboard-deprecation-banner-state');

	useEffect(() => {
		const localDashbaordBannersState = store.get(dashboardBannerStorageKey);
		if (localDashbaordBannersState) {
			if (bannerPerDashboard) {
				const bannerIsDismissed = localDashbaordBannersState.includes(dashboardId);
				setIsDashboardBannerDismissed(bannerIsDismissed);
			} else {
				setIsDashboardBannerDismissed(true);
			}
		}
	}, [dashboardId, dashboardBannerStorageKey, store, bannerPerDashboard]);

	const onDashboardBannerDismiss = () => {
		setIsDashboardBannerDismissed(true);
		const localDashbaordBannersState = store.get(dashboardBannerStorageKey);
		if (bannerPerDashboard) {
			if (localDashbaordBannersState) {
				localDashbaordBannersState.push(dashboardId);
				store.set(dashboardBannerStorageKey, localDashbaordBannersState);
			} else {
				store.set(dashboardBannerStorageKey, [dashboardId]);
			}
		} else if (!localDashbaordBannersState) {
			store.set(dashboardBannerStorageKey, true);
		}
	};

	return {
		isDashboardBannerDismissed,
		onDashboardBannerDismiss,
	};
};
